<template>
  <WidgetFrame  @onResize="onResize" @onOrientationChange="onOrientationChange">
    <template v-slot:title>
      {{ widget.name }}
    </template>
    <template v-slot:editor>
      <div :id="`DATAVIEW_WIDGET_BTN_ADD-${widget.uuId}`" v-on:click="onEdit" :class="{mobile: isTouchScreen}"><i class="far fa-pen-to-square"></i></div>
      <b-popover :target="`DATAVIEW_WIDGET_BTN_ADD-${widget.uuId}`" triggers="hover" placement="top" boundary="viewport">
        {{ $t('button.edit') }}
      </b-popover>
    </template>
    <template v-slot:content>
      <template v-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <div class="dataview-container">
          <div v-if="widget.image" class="dataview-header" :style="{'background-image': `url(${bannerUrl})` }"></div>
          <div v-if="widget.description" class="dataview-description">{{ widget.description }}</div>
          <div>
            <Dataview :isWidget="true" :dataviewId="widget.dataview.uuId" :widgetOwner="widget.owner" :dataviewPublic="widget.dataview.isPublic" :height="height - 100" :width="width" :dataviewComponent="widget.dataview.id"/>
          </div>
        </div>
        
        <DashboardDataviewModal
          v-if="dataviewShow"
          :widget="widget"
          @ok="dataviewUpdated"
          @cancel="modalCancel"
          />
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import WidgetFrame from "@/components/Dashboard/WidgetFrame";
import { costFormat } from '@/helpers';
import { convertDurationToDisplay } from '@/helpers/task-duration-process';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export default {
  name: 'LaunchpadWidget',
  components: {
    WidgetFrame,
    Dataview: () => import('@/views/dataview/Dataview.vue'),
    DashboardDataviewModal: () => import('@/components/modal/DashboardDataviewModal.vue')
  },
  props: {
    widget: { type: Object, required: true, default: function() {return null} },
    project: { type: Object, required: false, default: function() {return null} },
  },
  watch: {

  },
  computed: {
    isTouchScreen() {
      return window.matchMedia("(pointer: coarse)").matches
    },
    bannerUrl() {
      return `${this.baseUrl}api/file/${this.widget.image}`;
    },
  },
  data() {
    return {
      orientation: null,
      width: 0,
      height: 0,
      loaded: false,
      dataviewShow: false
    };
  },
  created() {
    this.loaded = true;
  },
  mounted() {
    
  },
  methods: {
    onOrientationChange({orientation, width, height}) {
      this.orientation = orientation;
      this.onResize({width, height});
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
    },
    modalCancel() {
      this.dataviewShow = false;
    },
    onEdit() {
      this.dataviewShow = true;
    },
    dataviewUpdated(widget) {
      this.$emit('dataviewUpdated', widget);
      this.dataviewShow = false;
    }
  }
}
</script>


<style lang="scss" scoped>
  .launchpad-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
  }
  .launchpad-header {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex: 1;
    min-height: 35%;
    margin-bottom: 10px;
  }
  .launchpad-description {
    color: var(--text-light);
    text-align: center;
    white-space: pre-line;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .launchpad-link {
    display: block !important;
    margin: 5px;
  }
</style>